import React from 'react'
import { Link } from 'gatsby'
import Image from "gatsby-image";
import { StaticQuery, graphql } from "gatsby"

//styles
import s from './card.module.scss'

export default ({ itemClassName }) => (
	<StaticQuery
		query={graphql`
      query {
        strapiPsAi {
          hero {
            main_heading
            service_icon {
              childImageSharp {
                fixed(width: 100, height: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    `}
		render={data => {

			const { hero } = data.strapiPsAi;

			return (
				<div className={`${itemClassName} ${s.industry}`}>
					<Link to="/solutions/artificial-intelligence/">
						<div>
							<div className={`hero-circle m-auto`}>
								<Image fixed={hero[0].service_icon.childImageSharp.fixed}  className={`${s.icon}`} />
							</div>
							<h3 className="text-center">{hero[0].main_heading}</h3>
						</div>
						<span className={`btn btn-secondary btn-lg ${s.button}`}>Learn More</span>
					</Link>
				</div>
			)
		}
		}
	/>
)
