import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import BackgroundImage from "gatsby-background-image";
// import scrollTo from "gatsby-plugin-smoothscroll";

//components
import ModalVideo from "../non-panels/react-modal-video/modal-video";
import PanelFoundation from "./PanelFoundation";

//images
import laptopBackground from "../../images/hero-laptop-3.png";
import playButton from "../../images/play-button-2138735_640.png";

//styles
import s from "./Hero-v2.module.scss";

const Hero = ({
  //from PanelFoundation
  rootClassName = "bg-white",
  rootStyle,
  rowClassName,
  rowStyle,
  overlay = true,
  overlayStyle,
  overlayColor,
  rootGatsbyBackgroundImageFluid,
  //component specific

  heading,
  icon,
  iconComponent,
  videoImage,
  isGatsbyLink,
  videoLink
}) => {
  const [isVideoModelOpen, setIsVideoModelOpen] = useState(false);
  const queryData = useStaticQuery(graphql`
    query getHeroHeaderImageV2 {
      allStrapiHomePage(sort: { fields: [created_at], order: DESC }, limit: 1) {
        edges {
          node {
            main_cover_image {
              childImageSharp {
                fluid(maxWidth: 1140) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);
  const headerBackgroundImage =
    queryData &&
    queryData.allStrapiHomePage &&
    queryData.allStrapiHomePage.edges &&
    queryData.allStrapiHomePage.edges[0] &&
    queryData.allStrapiHomePage.edges[0].node &&
    queryData.allStrapiHomePage.edges[0].node.main_cover_image &&
    queryData.allStrapiHomePage.edges[0].node.main_cover_image
      .childImageSharp &&
    queryData.allStrapiHomePage.edges[0].node.main_cover_image.childImageSharp
      .fluid;

  return (
    <>
      <PanelFoundation
        rootClassName={`${s.heroMain} ${
          videoLink ? "" : ` ${s.noLaptop}`
        } ${rootClassName || ""}`}
        rootStyle={rootStyle}
        rowClassName={`${s.hero} ${rowClassName}`}
        rowStyle={rowStyle}
        overlay={overlay}
        overlayStyle={overlayStyle}
        overlayColor={overlayColor}
        rootGatsbyBackgroundImageFluid={
          rootGatsbyBackgroundImageFluid || headerBackgroundImage
        }
      >
        <div className={`${s.content}`}>
          <div className={`${s.icon}`}>{!iconComponent && icon && <Img fixed={icon} />}{iconComponent && !icon ? iconComponent : "" }</div>
          {heading && <h1>{heading}</h1>}
        </div>
        {videoLink && (
          <div className={`${s.heroRight}  pt-5`}>
            <div
              className={`${s.computerWrapper} m-auto`}
              onClick={() => setIsVideoModelOpen(true)}
            >
              {!isGatsbyLink && (
                <img
                  src={videoImage}
                  className={s.computerOverlay}
                  style={{ borderImageSource: `url(${laptopBackground})` }}
                />
              )}
              <img src={playButton} className={s.playButton} />
            </div>
          </div>
        )}
      </PanelFoundation>
      {videoLink && (
        <ModalVideo
          channel={"custom"}
          ratio="16:9"
          autoplay={1}
          allowFullScreen={true}
          url={videoLink}
          isOpen={isVideoModelOpen}
          onClose={() => setIsVideoModelOpen(false)}
        />
      )}
    </>

  );
};

export default Hero;
