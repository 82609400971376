import React, { useState } from 'react'
import { useSpring, animated, config } from 'react-spring'
import {useTransitionHeight} from '../../utils/useMeasure'

//styles
import collapsibleStyle from "./Collapsible.module.scss"

const CollapsibleComponent = ({ contents, rootClassName, rootStyle }) => {
	const [collapsibleOpenArray, setCollapsibleOpenArray] = useState([...new Array(contents.length).fill(false)]);

	const handleCollapsibleToggle = (index) => {
		setCollapsibleOpenArray(collapsibleOpenArray.map((item, i) => {
			return i === index ? !item : item
		}))
	}

	return (
		<div className={`container-fluid panel-padding ${rootClassName || ""}`} style={rootStyle || {}}>
			<div className={`container`}>
				<div className={`row`}>
					<div className={`col-md-8 offset-md-2`}>
						{contents && collapsibleOpenArray.map((collapse, index) => {
							const [style, bind, toggle] = useTransitionHeight()
							// const [style, bind, toggle] = [{},() => {}, false];
							
							const chevronRotate = useSpring({
								from: {
									transform: 'rotateZ(0deg)'
								  },
								  to: {
									transform: collapse? 'rotateZ(90deg)' : 'rotateZ(0deg)'
								  },
							  })

							return (
								<div key={index} className={collapsibleStyle.block}>
									<div
										className={`${collapsibleStyle.heading} ${collapse ? collapsibleStyle.active : ""}`}
										onClick={() => { handleCollapsibleToggle(index); toggle(); }}
									>
										<p
										>
											<animated.i className={`fas fa-chevron-right`} style={chevronRotate}></animated.i> {contents[index].heading} 
											{/* //${collapse ? "fa-rotate-90" : ""} */}
										</p>
									</div>
									<animated.div 
										style={style}
										// {...bind}
										// style={{height:size}}
										className={`${collapsibleStyle.content}`}
									>
										<div {...bind} className={`${collapsibleStyle.indent} text-justify`}>
											<p>
												{contents[index].content}
											</p>
										</div>
									</animated.div>
								</div>
							)
						})}
					</div>
				</div>
			</div>
		</div >
	)
}

export default CollapsibleComponent;