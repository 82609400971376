import React from "react";
import { graphql } from "gatsby";
import Image from "gatsby-image";
import ReactMarkdown from "react-markdown";
import { LinkRenderer } from "../../utils/utilities";

//components
import useSetPageData from "../../utils/useSetPageData";
import Hero from "../../components/panels/Hero-v2";
import MoreCards from "../../components/panels/MoreCards";
import ComponentHeader from "../../components/panels/ComponentHeader";
import ListItem from "../../components/panels/ListItem";
import Collapsible from "../../components/panels/Collapsible";
import SwitchComponent from "../../components/panels/Switch";
import Approach from "../../components/panels/Approach";
import SEO from "../../components/seo";

//image
// import tempImg from '../../images/icons/random-free/iconfinder_database_4417104.png'

//styles
import s from "./chatbots.module.scss";

const ItConsultingPage = ({ location, data }) => {
  const {
    SEO: seo,
    hero,
    overview_description,
    virtual_overview_image,
    benefits,
    proficiencies,
    learn_more
  } = data.strapiPsVirtual;

  useSetPageData(location);

  return (
    <>
      <SEO
        {...{
          title: `AI Chatbot Solutions and Deployment Consulting | Solutions`,
          description: seo.description
        }}
      />
      <Hero
        heading={hero[0].main_heading}
        icon={hero[0].service_icon.childImageSharp.fixed}
        // videoImage={hero[0].services_main_image.childImageSharp.fixed.src}
        // videoLink={"/videos/final-virtual-assistant.mp4"}
      />
      {/* <div style={{ marginTop: "4rem" }}></div> */}
      <SwitchComponent
        contents={[
          {
            image: (
              <Image fluid={virtual_overview_image.childImageSharp.fluid} />
            ),
            content: (
              <ReactMarkdown
                source={overview_description}
                escapeHtml={false}
                renderers={{ link: LinkRenderer }}
              />
            )
          }
        ]}
      />
      <ComponentHeader
        rootClassName={`bg-white`}
        textClassName={`text-primary`}
        text={"Your Benefits"}
      />
      <ListItem
        columnsPerRow={3}
        hasCircle
        circleBorderColor={"var(--primary)"}
        circleColor={"white"}
        contents={benefits.map(b => ({
          image: <Image fixed={b.card_image.childImageSharp.fixed} />,
          heading: b.heading,
          content: (
            <p className={`text-justify`}>
              <ReactMarkdown
                source={b.content}
                escapeHtml={false}
                renderers={{ link: LinkRenderer }}
              />
            </p>
          )
        }))}
      />
      <ComponentHeader
        rootClassName={`bg-light`}
        textClassName={`text-primary`}
        text={"AI Chatbot Proficiencies"}
      />
      <ListItem
        columnsPerRow={2}
        // hasCircle
        // circleBorderColor={"var(--primary)"}
        // circleColor={"#fff"}
        isCard
        rootClassName={`bg-light`}
        // bgClassName={`${s.proBg}`}
        contents={proficiencies.map(b => ({
          image: <Image fixed={b.card_image.childImageSharp.fixed} />,
          heading: b.heading,
          content: (
            <p className={`text-justify`}>
              <ReactMarkdown
                source={b.content}
                escapeHtml={false}
                renderers={{ link: LinkRenderer }}
              />
            </p>
          )
        }))}
      />
      <ComponentHeader
        rootClassName={`bg-white`}
        textClassName={`text-primary`}
        text={"Learn More"}
      />
      <Collapsible
        contents={learn_more.map((lm, i) => ({
          heading: lm.heading,
          content: (
            <ReactMarkdown
              source={lm.content}
              escapeHtml={false}
              renderers={{ link: LinkRenderer }}
            />
          )
        }))}
        rootClassName={`${s.learnMore}`}
      />
      <ComponentHeader
        rootClassName={`bg-white`}
        textClassName={`text-primary`}
        text={"Our Approach"}
      />
      <Approach />
      <ComponentHeader
        rootClassName={`bg-light`}
        textClassName={`text-primary`}
        text={"More Solutions"}
      />
      <MoreCards
        rootClassName={`bg-light`}
        itemClassName={`col-lg-4 col-md-6 `}
        cards={["ArtificialIntelligence", "IntelligentAutomation", "RPA"]}
      />
    </>
  );
};

export default ItConsultingPage;

export const pageQuery = graphql`
  {
    strapiPsVirtual {
      SEO {
        description
      }
      hero {
        main_heading
        main_subheading
        services_main_image {
          childImageSharp {
            fixed(width: 250) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        service_icon {
          childImageSharp {
            fixed(width: 100, height: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      overview_description
      virtual_overview_image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      benefits {
        heading
        content
        card_image {
          childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      proficiencies {
        heading
        content
        card_image {
          childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      learn_more {
        heading
        content
      }
    }
  }
`;
