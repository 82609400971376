import React from "react";
import { Link } from "gatsby";

import PanelFoundation from "./PanelFoundation";
import ComponentHeader from "./ComponentHeader";

import ArtificialIntelligenceCard from "../non-panels/cards/ArtificialIntelligence";
import ChatbotsCard from "../non-panels/cards/Chatbots";
import ProjectImplementationsCard from "../non-panels/cards/ProjectImplementations";
import IntelligentAutomationCard from '../non-panels/cards/IntelligentAutomation'

import RpaCard from "../non-panels/cards/RPA";
import StaffAugmentationCard from "../non-panels/cards/StaffAugmentation";
import SupportServicesCards from "../non-panels/cards/SupportServices";

import s from "./MoreCards.module.scss";

//cards is an array of the following values: "ai", "cloud", "rpa", "it-consulting", "virtual-assistant"
const othercardsComponent = ({
  //from PanelFoundation
  id,
  rootClassName = "bg-white",
  rootStyle,
  rowClassName,
  rowStyle,
  overlay,
  overlayStyle,
  overlayColor,
  rootGatsbyBackgroundImageFluid,

  //component specific
  itemClassName,
  cards
}) => {
  return (
    <>
      <PanelFoundation
        id={id}
        rootClassName={`panel-padding ${s.headerPanelPadding} ${rootClassName || ""}`}
        rootStyle={rootStyle}
        rowClassName={rowClassName}
        rowStyle={rowStyle}
        overlay={overlay}
        overlayStyle={overlayStyle}
        overlayColor={overlayColor}
        rootGatsbyBackgroundImageFluid={rootGatsbyBackgroundImageFluid}
      >
        {cards && cards.includes("ArtificialIntelligence") && (
          <ArtificialIntelligenceCard
          itemClassName={itemClassName}
            // bgClassName={`col-lg-4 col-md-6 `}
          />
        )}
        {cards && cards.includes("Chatbots") && (
          <ChatbotsCard itemClassName={itemClassName} />
        )}
        {cards && cards.includes("ProjectImplementations") && (
          <ProjectImplementationsCard itemClassName={itemClassName} />
        )}
        {cards && cards.includes("RPA") && (
          <RpaCard itemClassName={itemClassName} />
        )}
        {cards && cards.includes("IntelligentAutomation") && (
          <IntelligentAutomationCard itemClassName={itemClassName} />
        )}
        
        {cards && cards.includes("StaffAugmentation") && (
          <StaffAugmentationCard itemClassName={itemClassName} />
        )}
        {cards && cards.includes("SupportServices") && (
          <SupportServicesCards itemClassName={itemClassName} />
        )}
      </PanelFoundation>
      {/* <div
        className={`container-fluid panel-padding ${othercardsStyles.cards}`}
      >
        <div className={`container ${othercardsStyles.fullHeight}`}>
          <div className={`row ${othercardsStyles.fullHeight}`}>
            {cards && cards.includes("ArtificialIntelligence") && (
              <ArtificialIntelligenceCard
                rootClassName={itemClassName}
                // bgClassName={`col-lg-4 col-md-6 `}
              />
            )}
            {cards && cards.includes("Chatbots") && (
              <ChatbotsCard rootClassName={itemClassName} />
            )}
            {cards && cards.includes("ProjectImplementations") && (
              <ProjectImplementationsCard rootClassName={itemClassName} />
            )}
            {cards && cards.includes("RPA") && (
              <RpaCard rootClassName={itemClassName} />
            )}
            {cards && cards.includes("StaffAugmentation") && (
              <StaffAugmentationCard rootClassName={itemClassName} />
            )}
            {cards && cards.includes("Supportcards") && (
              <SupportcardsCard rootClassName={itemClassName} />
            )}
          </div>
        </div>
      </div> */}
    </>
  );
};

export default othercardsComponent;
