import React from "react";

//component
import PanelFoundation from "./PanelFoundation";

//image
import tempImage from "../../images/temp-slider-image.jpg";

//style
import s from "./Switch.module.scss";

const SwitchComponent = ({
  //component specific
  oddClassName = "bg-primary text-white",
  oddStyle,
  evenClassName,
  eventStyle,
  textColStyles = {},
  imageColStyles = {},
  panelPadding = true,
  //[{image, heading, content}]
  contents
}) => {
  return (
    <>
      {contents &&
        contents.map((content, index) => (
          <PanelFoundation
            rootClassName={`${panelPadding? "panel-padding" : "" } ${
              index % 2 === 0 ? evenClassName || "" : oddClassName || ""
            }`}
          >
            <div
              className={`${s.main} col-lg-6 col-md-12 mb-5 ${index % 2 === 0 ? "order-lg-2" : ""} ${s.rightPanel} ${s.imagePanel}`}
              style={imageColStyles}
            >
              {content.image ? content.image : <img src={tempImage} />}
            </div>
            <div
              className={`col-lg-6 col-md-12 ${index % 2 === 0 ? "order-lg-1" : ""} 
              ${
                // hardcoded this condition because the component used for more than one page.
                content.heading === "IPA Integration" || 
                content.heading === "Increase Productivity" ||
                content.heading === "Empowering Users" ||
                content.heading === "It's a User too." ||
                content.heading === "Intelligent Integration"
                ? s.leftPanel : null
              } 
              ${s.a}`}
              style={textColStyles}
            >
              <h3 className={`mb-4`}>{content.heading}</h3>
              <div className={`text-justify`}>{content.content}</div>
            </div>
          </PanelFoundation>
        ))}
    </>
  );
};

export default SwitchComponent;
