import React from "react";

//icons
import assessmentIcon from "../../images/Approach/assessment.png";
import implementationIcon from "../../images/Approach/implementation.png";
import manageIcon from "../../images/Approach/manage_maintain.png";
import strategyIcon from "../../images/Approach/strategy.png";

//styles
import "./Approach.module.scss";

export default () => (
  <div class={`h2-step-all-box container`}>
    <div class="h2-step-box">
      <div class="icon">
        <img src={strategyIcon} className={"icon-img"} />
        {/* <span class="flaticon-graphic"></span> */}
      </div>
      <div class="content">
        <h4 class="title">Strategy</h4>
        <p class="text">
          Develop operations and technology strategy recommendations to enable
          overall organizational goals.
        </p>
      </div>
    </div>
    <div class="h2-step-box">
      <div class="icon">
        <img src={assessmentIcon} className={"icon-img"} />
        {/* <span class="flaticon-gestures"></span> */}
      </div>
      <div class="content">
        <h4 class="title">Assessment</h4>
        <p class="text">
          Assess organization processes and applications to prioritize suitable
          process initiatives to gain maximum value.
        </p>
      </div>
    </div>
    <div class="h2-step-box">
      <div class="icon">
        <img src={implementationIcon} className={"icon-img"} />
        {/* <span class="flaticon-strategical-planning"></span> */}
      </div>
      <div class="content">
        <h4 class="title">Implementation</h4>
        <p class="text">
          Partner our technical and functional skills and experience with your
          organizational knowledge to implement the best automated process
          solutions for your needs.
        </p>
      </div>
    </div>
    <div class="h2-step-box">
      <div class="icon">
        <img src={manageIcon} className={"icon-img"} />
        {/* <span class="flaticon-exam"></span> */}
      </div>
      <div class="content">
        <h4 class="title">Managed Services</h4>
        <p class="text">
          Provide managed services for clients’ Applications/Business processes
          and own maintenance.
        </p>
      </div>
    </div>
  </div>
);
