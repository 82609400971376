import React from "react";
import { Col } from "react-bootstrap";

//conpoments
import PanelFoundation from "./PanelFoundation";

//styles
import s from "./ComponentHeader.module.scss";

const ComponentHeader = ({
  //from PanelFoundation
  id,
  rootClassName = "bg-white",
  rootStyle,
  rowClassName,
  rowStyle,
  overlay,
  overlayStyle,
  overlayColor,
  rootGatsbyBackgroundImageFluid,
  //component specific
  lineTop = true,
  text = "Header",
  textAlign = "center",
  textClassName = "text-primary"
}) => {

  const textStyle = {};

  switch (textAlign.toLowerCase()) {
    case "center":
      textStyle.margin = "0 auto";
      break;
    case "left":
      textStyle.margin = "0 auto 0 0";
      break;
    case "right":
      textStyle.margin = "0 0 0 auto";
      break;
  }

  return (
    <PanelFoundation
      id={id}
      rootClassName={`${s.headerPanelPadding} ${rootClassName || ""}`}
      rootStyle={rootStyle}
      rowClassName={rowClassName}
      rowStyle={rowStyle}
      overlay={overlay}
      overlayStyle={overlayStyle}
      overlayColor={overlayColor}
      rootGatsbyBackgroundImageFluid={rootGatsbyBackgroundImageFluid}
    >
      <Col sm={12}>
        <h2
          className={`${lineTop ? s.lineTop : ""} ${textClassName || ""} text-center`}
          style={textStyle}
        >
          {text}
        </h2>
      </Col>
    </PanelFoundation>
  );
};

export default ComponentHeader;
